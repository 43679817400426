<br>
<p-dialog header="Eliminar Análisis" [(visible)]="showEliminaAnalisis" (onShow)="scrollToTop()">
    <div class="container" style="max-width:480px; width: auto; overflow: auto">
        ¿Seguro que desea eliminar este análisis?
    </div>
    <div class="container" style="text-align:center; max-width:480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showEliminaAnalisis = false;">No</button>
        <button class="btn btn-danger" style="margin: 10px;" (click)="eliminaAnalisis();">Sí</button>
    </div>
</p-dialog>    

<p-dialog *ngIf="analisisSeleccionado?.id" header="Fecha y Hora" [(visible)]="showEditAnalisis" (onShow)="scrollToTop()">
    <div class="container" style="max-width:480px; width: auto; overflow: auto">
    <p-calendar #analisisCalendar
        [(ngModel)]=date
        [stepMinute]=30
        [inline]="true"
        [showTime]="true">
    </p-calendar>
    </div>
    <div class="container" style="text-align:center; max-width:480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showEditAnalisis = false;">Volver</button>
    </div>
</p-dialog>

<div id="leyenda">
    <div class="wrapper"><div class="square red"></div>Pendientes</div>
    <div class="wrapper"><div class="square blue"></div>Programados</div>
    <div class="wrapper"><div class="square orange"></div>Solicitados</div>
    <div class="wrapper"><div class="square green"></div>Realizados</div>
</div>

<div id="gotodate-div">
    <p>Ir a fecha:</p>
    <p-dropdown id="month-dropdown" [options]="months" [(ngModel)]="selectedMonth" placeholder="Mes" 
    optionLabel="name" [showClear]="true" (onChange)="showSelectedDate(calendar)"></p-dropdown>
    <p-dropdown id="year-dropdown" [options]="years" [(ngModel)]="selectedYear" placeholder="Año" 
    optionLabel="name" [showClear]="true" (onChange)="showSelectedDate(calendar)"></p-dropdown>
    <button *ngIf="this.appName === 'pyf'" class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem;  background-color: #16A2B7; color: white">
        <i class="fa fa-download"></i>
            Exportar listado
    </button>
    <button *ngIf="this.appName === 'viverospereira'" class="mx-2 btn btn-info" (click)="uploadDialog()" style="padding: 5px; font-size: .8rem;">
        <i class="fa fa-upload"></i>            
        Importar Análisis
    </button>
</div>

<div class="row" style="width: auto; overflow: auto">
    <div class="col-6 me-5">
        <full-calendar #calendar
                [events]="analisisArrayFiltered"
                [options]="options">
        </full-calendar>
    </div>

    <div class="col-5" style="margin-left: 0px; margin-top: 40px">
        <div class="d-flex justify-content-end align-items-start">
            <button type="button" class="btn" style="padding: 5px; font-size: .8em; margin-right: 0.5rem; background-color: #16A2B7; color: white" (click)="reloadAnalisis()">
            <span>
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </span>
               
            </button>
            <button *ngIf="this.rol !== 'gerencia'" type="button" class="btn" style="padding: 5px; font-size: .8em; background-color: #27A745;color:white;" (click)="crearAnalisis()">
                Añadir Recordatorio
            </button>
        </div>
        <p-panel>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2>Datos del Análisis</h2>
                        <div>
                            <button *ngIf="analisisSeleccionado?.id"
                                    (click)="showEliminaAnalisis=true"
                                    class="btn btn-danger"
                                    style="height: 30px; width: 30px; margin-right: 15px;">
                                <i class="fa fa-trash"
                                style="
                                    position: relative;
                                    bottom: 4px;
                                    right: 4px;">
                                </i>
                            </button>

                            <button *ngIf="analisisSeleccionado?.id"
                                    (click)="editAnalisis(analisisSeleccionado)"
                                    class="btn btn-dark"
                                    style="height: 30px; width: 30px; margin-right: 15px;">
                                <i class="fa fa-pencil"
                                style="
                                    position: relative;
                                    bottom: 4px;
                                    right: 4px;">
                                </i>
                            </button>
                            <button *ngIf="analisisSeleccionado?.id"
                                    (click)="duplicateAnalisis(analisisSeleccionado)"
                                    class="btn btn-dark"
                                    style="height: 30px; width: 30px">
                                <i class="fa fa-copy"
                                style="
                                    position: relative;
                                    bottom: 4px;
                                    right: 4px;">
                                </i>
                            </button>
                        </div>
                    </div>

                    <br />
                    <h6>Tipo de análisis: {{analisisSeleccionado.tipo_analisis}}</h6>
                    <h6 *ngIf="hasClients && isAdmin">{{clienteName}}: {{analisisSeleccionado.id_usuario === 1 && this.applicationType === 'cropgest' ? 'Todos' : analisisSeleccionado.cliente_nombre}}</h6>
                    <h6 *ngIf="hasFincas">{{fincaName}}: {{analisisSeleccionado.id_usuario === 1 && this.applicationType === 'cropgest' ? 'Todas' : analisisSeleccionado.finca_nombre}}</h6>
                    <h6 *ngIf="showAnalisisParcelas">{{parcelasName}}: {{analisisSeleccionado.parcela_nombre}}</h6>
                    <h6 *ngIf="appName === 'viverospereira'">{{sectoresName}}: {{analisisSeleccionado.sector_nombre}}</h6>
                    <h6>Fecha: {{analisisSeleccionado.fecha}}</h6>
                    <h6 *ngIf="appName === 'viverospereira'">Semana: {{analisisSeleccionado.semana}}</h6>
                    <h6 *ngIf="appName === 'viverospereira'">Variedad: {{analisisSeleccionado.variedad}}</h6>
                    <h6 *ngIf="appName === 'viverospereira'">Formato: {{analisisSeleccionado.formato}}</h6>
                    <h6 *ngIf="appName === 'viverospereira'">Remesa: {{analisisSeleccionado.remesa}}</h6>
                    <h6>Muestra: {{analisisSeleccionado.muestra}}</h6>
                    <h6>Frecuencia: {{analisisSeleccionado.frecuencia}}</h6>
                    <h6 *ngIf="showAnalisisM3Agua">Agua m<sup>3</sup>: {{analisisSeleccionado.agua_m}}</h6>
                    <h6 *ngIf="showAnalisisMgrl || showCultivosBajoAguaFields">Nitratos (mg/l): {{analisisSeleccionado.mgrl}}</h6>
                    <h6 *ngIf="appName==='ava' || appName==='fitogest'">Objeto del análisis: {{analisisSeleccionado.objeto_analisis}}</h6>
                    <h6 *ngIf="analisisSeleccionado?.materia_activa">
                        <span *ngIf="appName !== 'laplana'">Notas: </span>
                        <span *ngIf="appName === 'laplana'">Notas (cultivo): </span>
                        {{analisisSeleccionado.materia_activa}}
                    </h6>
                    <h6 *ngIf="analisisSeleccionado?.volumen_kg">
                        Volumen (kg): {{analisisSeleccionado.volumen_kg}}</h6>
                    <h6 *ngIf="showAnalisisNumeroAnalisis">Referencia Análisis: {{analisisSeleccionado.analisis}} </h6>
                    <br>
                    <div *ngIf="applicationType === 'viverogest'">
                        <h4>Resultados:</h4>
                        <h6>pH: {{analisisSeleccionado.pH}}</h6>
                        <h6>Conductividad: {{analisisSeleccionado.conductividad}}</h6>
                        <h6>Sulfatos: {{analisisSeleccionado.sulfatos}}</h6>
                        <h6>Nitratos: {{analisisSeleccionado.nitratos}}</h6>
                        <h6>Valoración: {{analisisSeleccionado.valoracion}}</h6>
                    </div>
                    <h6>Estado: {{analisisSeleccionado.estado}}
                        <!-- id="buttons-right" --> 
                        <div  class="alinear_botones_analisis" style="text-align: left;">
                            <div *ngIf="analisisSeleccionado?.adjunto">
                                <button *ngIf="!isMobile" type="button" class="btn btn-info" style="color: white; max-width: 150px;min-width: 150px;"
                                    (click)="verAdjunto(analisisSeleccionado.adjunto ?? '')">
                                    Ver análisis
                                </button>
                                <button *ngIf="isMobile" type="button" class="btn btn-info" style="color: white; max-width: 150px;min-width: 150px;"
                                    (click)="descargarAdjunto(analisisSeleccionado.adjunto ?? '')">
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                            <!-- Adjuntar análisis -->
                            <div *ngIf="analisisSeleccionado?.estado === 'Realizada'" class="adjuntar-analisis-button">
                                <input type="file" #adjuntarAnalisis (change)="fileChange($event)" style="display: none;"/>
                                <button *ngIf="model?.adjunto" class="btn btn-link" (click)="verAdjunto(analisisSeleccionado.adjunto ?? '')">Ver</button>
                                <input type="button" class="btn btn-sm btn-success" 
                                value="Adjuntar análisis" 
                                style="color: white; max-width: 150px; min-width: 150px; padding: 9px 8px; border-radius: 4px;" 
                                (click)="readUrl(adjuntarAnalisis)" />
                            </div>
                            <!-- Cambiar estado -->
                            <button *ngIf="analisisSeleccionado?.id" [disabled]="analisisSeleccionado.estado === 'Realizada'" 
                            [style.backgroundColor]="cambiaEstadoColor" style="color: white; max-width: 150px;min-width: 150px;" class="btn btn-sm estado-button" 
                            #cambiaEstadoButton (click)="cambiaEstado(cambiaEstadoButton)" >
                                {{cambiaEstadoLabel}}
                                <img *ngIf="cambiandoEstado" style="width: 16px; height: 16px;" src="./assets/icon/load.gif" alt="Cargando"/>
                            </button>
                        </div>
                    </h6>
                   
                </div>
            </div>

        </p-panel>
    </div>
</div>

<!-- SUBIR/IMPORTAR ARCHIVO ANÁLISIS -->
<p-dialog width="600" header="Importar datos Análisis" [closeOnEscape]="false" [closable]="false" [(visible)]="showModalImport" (onShow)="scrollToTop()">

    <div *ngIf="showModalImport" style="width: 36vw !important">

        <div>
            <p-fileUpload name="myfile[]" [disabled]="!enableUpload" multiple="false" [showCancelButton]="false" chooseLabel="Seleccionar archivo" uploadLabel="Enviar" [url]="urlUpload" (onSelect)="checkFile($event)" (onUpload)="uploaded($event)">
            </p-fileUpload>
        </div>

        <div *ngIf="showImportError">
            <br>
            <p style="max-width: 400px; text-align: center; color: red" [innerHTML]="txtImportError"></p>
            <p style="max-width: 400px; text-align: center; color: green" [innerHTML]="txtImportInfo"></p>

        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="closeFUDialog();">Cerrar</button>
        </div>
        <br>
    </div>
</p-dialog>
<br>

<a id="downloadAdjunto" href="#" download="Adjunto" style="visibility: hidden; position: fixed;"></a>

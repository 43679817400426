<br>
<p-dialog header="Eliminar CRM" [(visible)]="showEliminaCRM" (onShow)="scrollToTop()">
    <div class="container" style="max-width:480px; width: auto; overflow: auto">
        ¿Seguro que desea eliminar este recordatorio?
    </div>
    <div class="container" style="text-align:center; max-width:480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showEliminaCRM = false;">No</button>
        <button class="btn btn-danger" style="margin: 10px;" (click)="eliminaCRM();">Sí</button>ped
    </div>
</p-dialog>    

<p-dialog *ngIf="analisisSeleccionado?.id" header="Fecha y Hora" [(visible)]="showEditCRM" (onShow)="scrollToTop()">
    <div class="container" style="max-width:480px; width: auto; overflow: auto">
    <p-calendar #analisisCalendar
        [(ngModel)]=date
        [stepMinute]=30
        [inline]="true"
        [showTime]="true">
    </p-calendar>
    </div>
    <div class="container" style="text-align:center; max-width:480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showEditCRM = false;">Volver</button>
    </div>
</p-dialog>

<div id="leyenda">
    <div class="wrapper"><div class="square blue"></div>Programada</div>
    <div class="wrapper"><div class="square green"></div>Realizada</div>
    <div class="wrapper"><div class="square red"></div>No Realizada</div>
</div>

<div id="gotodate-div">
    <p>Ir a fecha:</p>
    <p-dropdown id="month-dropdown" [options]="months" [(ngModel)]="selectedMonth" placeholder="Mes" 
    optionLabel="name" [showClear]="true" (onChange)="showSelectedDate(calendar)"></p-dropdown>
    <p-dropdown id="year-dropdown" [options]="years" [(ngModel)]="selectedYear" placeholder="Año" 
    optionLabel="name" [showClear]="true" (onChange)="showSelectedDate(calendar)"></p-dropdown>
</div>

<div class="row" style="width: auto; overflow: auto">
    <div class="col-7">
        <full-calendar #calendar
                [events]="analisisArrayFiltered"
                [options]="options">
        </full-calendar>
    </div>

    <div class="col-5" style="margin-left: 0px; margin-top: 77px">
        <div class="d-flex justify-content-between">
            <button tooltipPosition="top" pTooltip="Imprimir" class="btn btn-secondary"
            style="font-size: .7em;" (click)="showImprimir()">
                <i class="fa fa-print"></i>
            </button>
            <button type="button" class="btn btn-info" style="padding: 5px; font-size: .8em;" (click)="reloadAnalisis()">
            <span>
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </span>
            </button>
            <button type="button" class="btn btn-success" style="padding: 5px; font-size: .8em;" (click)="crearCRM()">
                Añadir Tarea
            </button>
        </div>
        <p-panel>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2>Tarea comercial</h2>
                        <div>
                            <button *ngIf="analisisSeleccionado?.id"
                                    (click)="showEliminaCRM=true"
                                    class="btn btn-danger"
                                    style="height: 30px; width: 30px; margin-right: 20px;">
                                <i class="fa fa-trash"
                                style="
                                    position: relative;
                                    bottom: 4px;
                                    right: 4px;">
                                </i>
                            </button>

                            <button *ngIf="analisisSeleccionado?.id"
                                    (click)="editCRM(analisisSeleccionado)"
                                    class="btn btn-dark"
                                    style="height: 30px; width: 30px">
                                <i class="fa fa-pencil"
                                style="
                                    position: relative;
                                    bottom: 4px;
                                    right: 4px;">
                                </i>
                            </button>
                        </div>
                    </div>

                    <br />
                    <h6 *ngIf="isAdmin">Cliente: {{analisisSeleccionado.cliente.nombre}} {{analisisSeleccionado.cliente.apellidos}}</h6>
                    <h6>Fecha: {{analisisSeleccionado.fecha}}</h6>
                    <h6>Realizado: {{analisisSeleccionado.realizado === '1'? 'Sí': 'No' }}</h6>
                    <h6>Tarea comercial: {{analisisSeleccionado.tarea_comercial }}</h6>
                    <h6 *ngIf="isAdmin">
                        Estado del presupuesto: {{analisisSeleccionado.estado_presupuesto}}
                    </h6>
                    <h6>
                        Observaciones: {{analisisSeleccionado.observaciones}}
                    </h6>
                    <!-- <div style="max-width: 60%; right: 2rem;">
                        <div  style="display: flex;">
                          <button *ngIf="analisisSeleccionado?.archivo" class="btn btn-link" (click)="verArchivo(analisisSeleccionado?.archivo)">Ver</button>
                        </div>
                      </div> -->
                   
                    <h6>
                        
                        <div  class="alinear_botones_analisis" style="text-align: left;">
                            <div *ngIf="analisisSeleccionado?.archivo">
                                <button *ngIf="!isMobile" type="button" class="btn btn-info" style="color: white; max-width: 150px;min-width: 150px;"
                                    (click)="verArchivo(analisisSeleccionado.archivo ?? '')">
                                    Ver documento
                                </button>
                                <!-- <button *ngIf="isMobile" type="button" class="btn btn-info" style="color: white; max-width: 150px;min-width: 150px;"
                                    (click)="descargarArchivo(analisisSeleccionado.archivo)">
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button> -->
                            </div>
                           
                        </div>
                    </h6>
                   
                </div>
            </div>

        </p-panel>
    </div>
</div>
<br>
<p-dialog draggable="false" header="Ver Tareas Comerciales" [(visible)]="showCRMImprimir">

    <div style="max-width: 900px; height: 50vh; overflow-y: scroll;">
        <div id="html2canvasDiv" class="p-2">
            <div *ngIf="crmImprimirAll.length > 100" class="w-100 mb-2" data-html2canvas-ignore>
                <p class="d-flex justify-content-between small text-secondary">
                    Se muestran {{imprimirMostrarTodos ? crmImprimirAll.length : 'los primeros 100'}} tareas comerciales.
                    <button class="btn btn-link" style="font-size: .7rem;"
                        (click)="imprimirMostrarTodos = !imprimirMostrarTodos">
                        <span *ngIf="!imprimirMostrarTodos">
                            Mostrar todos ({{crmImprimirAll.length || 0}})
                        </span>
                        <span *ngIf="imprimirMostrarTodos">
                            Mostrar menos
                        </span>
                    </button>
                </p>
            </div>

            <h5 class="my-1">Listado de tareas comerciales</h5>

            <table class="table-bordered table-striped table-hover table-print" style="font-size: .7rem;">
                <thead style="white-space: nowrap;">
                    <th *ngIf="isAdmin">Cliente</th>
                    <th>Fecha</th>
                    <th>Realizado</th>
                    <th>Tarea Comercial</th>
                    <th *ngIf="isAdmin">Estado del Presupuesto</th>
                    <th>Observaciones</th>
                </thead>
                <tbody *ngIf="!imprimirMostrarTodos">
                    <tr *ngFor="let crm of analisis">
                        <td *ngIf="isAdmin">{{crm?.cliente?.nombre}} {{crm?.cliente?.apellidos}}</td>
                        <td>{{crm?.fecha}}</td>
                        <td>{{crm?.realizado === '1' ? "Sí" : "No"}}</td>
                        <td>{{crm?.tarea_comercial}}</td>
                        <td *ngIf="isAdmin">{{crm?.estado_presupuesto}}</td>
                        <td>{{crm?.observaciones}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="imprimirMostrarTodos">
                    <tr *ngFor="let crm of crmImprimirAll">
                        <td *ngIf="isAdmin">{{crm?.cliente?.nombre}} {{crm?.cliente?.apellidos}}</td>
                        <td>{{crm?.fecha}}</td>
                        <td>{{crm?.realizado}}</td>
                        <td>{{crm?.tarea_comercial}}</td>
                        <td *ngIf="isAdmin">{{crm?.estado_presupuesto}}</td>
                        <td>{{crm?.observaciones}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <br />

    <div class="d-flex justify-content-center">
        <button class="btn btn-dark btn-sm mx-2" (click)="showCRMImprimir = false">Cerrar</button>
        <button class="btn btn-secondary btn-sm mx-2" (click)="imprimir()" [disabled]="isPrinting">
            {{isPrinting ? 'Imprimiendo...' : 'Imprimir'}}
            <i class="fa fa-print"></i>
        </button>Documents
    </div>

</p-dialog>
<p-dialog header="Eliminar registro" [(visible)]="showEliminaCRM">
    <div class="container" style="max-width:480px; width: auto; overflow: auto">
        ¿Seguro que desea eliminar este registro?
    </div>
    <div class="container" style="text-align:center; max-width:480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showEliminaCRM = false;">No</button>
        <button class="btn btn-danger" style="margin: 10px;" (click)="eliminaCRM();">Sí</button>
    </div>
</p-dialog>
<a id="downloadAdjunto" href="#" download="Adjunto" style="visibility: hidden; position: fixed;"></a>

import { createCsv } from 'src/app/common/createCsv';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import { Component } from '@angular/core';
import { Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import moment, { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import {StorageManager} from '../../../../common/storage-manager.class';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaEdificiosModel } from 'src/app/models/tareas/tarea-edificios.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

const TRUTHY_VALUES = [1, '1', true, 'true'];

@Component({
    selector: 'app-tareas-edificios',
    templateUrl: './tareas-edificios.component.html',
    styleUrls: ['./tareas-edificios.component.scss']
})
export class TareasEdificiosComponent extends BaseView<TareaEdificiosModel> implements OnInit, AfterViewInit {

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;
  
    public appName = environment.appName;
    public fincaName = environment.features.fincaName; 
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
  (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));     
    public serverUrl = environment.serverUrl;
  
    public getRequest: RequestHandler<TareaEdificiosModel[]> = this.tareasApi.edificios.GET;
    public deleteRequest: RequestHandler<TareaEdificiosModel> = this.tareasApi.edificios.DELETE;
    public pathToForm = 'crear-edificio';  
    public menuItemId = 'edificios';  

    public override filtering: Filtering<TareaEdificiosModel> = new Filtering<TareaEdificiosModel>();
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public override canShow: Record<string, boolean> = {};
    public override model: TareaEdificiosModel = {};
  
    public showButtonDuplicate = true;
    public distributionOrder = [3];
  
    public formFields: ItemInterface<object>[] =
        [
            {
                field: 'notas_cliente',
                label: 'Notas cliente',
                inputType: {type: InputType.TEXTAREA},
                visible: environment.features.showTareasMto ? true : false
            },
            {
                field: 'cliente_nombre', 
                name: 'Cliente', 
                label: 'Cliente', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha', 
                name: 'Fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'finca', 
                name: this.fincaName, 
                label: this.fincaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'prioridad', 
                name: 'Prioridad/Realizada', 
                label: 'Prioridad', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsJardin: ItemInterface<object>[] = 
        [
            {
                field: 'hora_inicio_jardin', 
                name: 'Hora Inicio JARDÍN', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}
            },
            {
                field: 'hora_fin_jardin', 
                name: 'Hora Fin JARDÍN', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}
            },
            {
                field: 'horas_jardin', 
                name: 'Horas Totales JARDÍN', 
                label: 'Horas totales', 
                inputType: {type: InputType.ONLY_TEXT} 
            },
        ];

    public formFieldsPiscinaHoras: ItemInterface<object>[] = 
        [
            {
                field: 'hora_inicio_piscina', 
                name: 'Hora Inicio PISCINA', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}
            },
            {
                field: 'hora_fin_piscina', 
                name: 'Hora Fin PISCINA', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}
            },
            {
                field: 'horas_piscina', 
                name: 'Horas Totales PISCINA', 
                label: 'Horas totales', 
                inputType: {type: InputType.ONLY_TEXT} 
            },
        ];  

    public formFieldsPiscinaTareas01: ItemInterface<object>[] = 
        [
            {
                field: 'barredera', 
                name: 'Barredera', 
                label: 'Barredera', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'cepillado', 
                name: 'Cepillado Paredes/Fondo', 
                label: 'Cepillado Paredes/Fondo', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_red', 
                name: 'Limpieza con Red', 
                label: 'Limpieza con Red', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'rascado_linea_flotacion', 
                name: 'Rascado Línea Flotación', 
                label: 'Rascado Línea Flotación', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
        ];

    public formFieldsPiscinaTareas02: ItemInterface<object>[] =
        [
            {
                field: 'comprobar_parametros_ph', 
                name: 'Ph', 
                label: 'Ph', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_cloro', 
                name: ' Cloro', 
                label: 'Cloro', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_acido_isocianurico', 
                name: 'Acido Isocianurico', 
                label: 'Acido Isocianurico', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_alcalinidad', 
                name: 'Alcalinidad', 
                label: 'Alcalinidad', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_fosfatos', 
                name: 'Fosfatos', 
                label: 'Fosfatos', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsPiscinaTareas03: ItemInterface<object>[] =
        [
            {
                field: 'lavado_enjuague_cesti', 
                name: 'Lavado/Enjuague/Cesto Bomba', 
                label: 'Lavado/Enjuague/Cesto Bomba', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_duchas', 
                name: 'Limpieza Duchas', 
                label: 'Limpieza Duchas', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_skimmer', 
                name: 'Limpieza Skimmer', 
                label: 'Limpieza Skimmer', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'nivel_vaso', 
                name: 'Nivel del Vaso', 
                label: 'Nivel del Vaso', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_cuarto_depuradora', 
                name: 'Limpieza Cuarto Depuradora', 
                label: 'Limpieza Cuarto Depuradora', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
        ];

    public formFieldsPiscinaProductos01: ItemInterface<object>[] = 
        [ 
            {
                field: 'cloro_liquido', 
                name: '[Cloro] Líquido (litros)', 
                label: 'Líquido (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cloro_granulado', 
                name: '[Cloro] Granulado (kilos)', 
                label: 'Granulado (kilos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cloro_pastillas', 
                name: '[Cloro] Pastillas', 
                label: 'Pastillas', 
                inputType: {type: InputType.TEXT}
            },
        ]; 

    public formFieldsPiscinaProductos02: ItemInterface<object>[] = 
        [ 
            {
                field: 'floculante_liquido', 
                name: '[Floculante] Líquido (litros)', 
                label: 'Líquido (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'floculante_pastillas', 
                name: '[Floculante] Pastillas', 
                label: 'Pastillas', 
                inputType: {type: InputType.TEXT}
            },
        ]; 

    public formFieldsPiscinaProductos03: ItemInterface<object>[] = 
        [ 
            {
                field: 'coagulante', 
                name: 'Coagulante (litros)', 
                label: 'Coagulante (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'algicida', 
                name: 'Algicida (litros)', 
                label: 'Algicida (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'alcalinidad', 
                name: 'Alcalinidad (kg)', 
                label: 'Alcalinidad (kg)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'ph_minus', 
                name: 'PH- (litros)', 
                label: 'PH- (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'ph_plus', 
                name: 'PH+ (litros)', 
                label: 'PH+ (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'activador', 
                name: 'Activador (litros)', 
                label: 'Activador (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'enzimatic', 
                name: 'Enzimatic (litros)', 
                label: 'Enzimatic (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'desincrustante', 
                name: 'Desincrustante (litros)', 
                label: 'Desincrustante (litros)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'eliminador_fosfatos', 
                name: 'Eliminador Fosfatos (kg)', 
                label: 'Eliminador Fosfatos (kg)', 
                inputType: {type: InputType.TEXT}
            },
        ]; 

    public formFieldsPiscinaRecambios: ItemInterface<object>[] =
        [
            {
                field: 'arena_silice', 
                name: 'Arena Sílice (25kg)', 
                label: 'Arena Sílice (25kg)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'limpiador_silex', 
                name: 'Limpiador Silex', 
                label: 'Limpiador Silex', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'vidrio', 
                name: 'Vidrio (20kg)', 
                label: 'Vidrio (20kg)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sacos_sal', 
                name: 'Sacos Sal (25kg)', 
                label: 'Sacos Sal (25kg)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'limpiador_fotocelulas', 
                name: 'Limpiador Fotocelulas', 
                label: 'Limpiador Fotocelulas', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cesto_skimmer', 
                name: 'Cesto Skimmer', 
                label: 'Cesto Skimmer', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tapas_skimmer', 
                name: 'Tapas Skimmer', 
                label: 'Tapas Skimmer', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'rejillas_sumidero', 
                name: 'Rejillas Sumidero', 
                label: 'Rejillas Sumidero', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'foco_luz', 
                name: 'Focos Luz', 
                label: 'Foco Luz', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'alcachofa_ducha', 
                name: 'Alcachofa Ducha', 
                label: 'Alcachofa Ducha', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'pomo_sencilla', 
                name: 'Pomo Sencilla', 
                label: 'Pomo Sencilla', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'pomo_completo', 
                name: 'Pomo Completo', 
                label: 'Pomo Completo', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'red_piscina', 
                name: 'Red Piscina', 
                label: 'Red Piscina', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'pertiga', 
                name: 'Pertiga', 
                label: 'Pertiga', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'bomba', 
                name: 'Bomba', 
                label: 'Bomba', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cesto_bomba', 
                name: 'Cesto Bomba', 
                label: 'Cesto Bomba', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'escalera', 
                name: 'Escalera', 
                label: 'Escalera', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'rejillas_transversales', 
                name: 'Rejillas Transversales', 
                label: 'Rejillas Transversales', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'impulsores_boquilla', 
                name: 'Impulsores/Boquilla', 
                label: 'Impulsores/Boquilla', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'neat_clean', 
                name: 'Neat Clean', 
                label: 'Neat Clean', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'dosificadores_orp', 
                name: 'Dosificadores ORP', 
                label: 'Dosificadores ORP', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'dosificadores_ph', 
                name: 'Dosificadores PH', 
                label: 'Dosificadores PH', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sonda_orp', 
                name: 'Sonda ORP', 
                label: 'Sonda ORP', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sonda_ph', 
                name: 'Sonda PH', 
                label: 'Sonda PH', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'dosificador_pastillas', 
                name: 'Dosificador Pastillas', 
                label: 'Dosificador Pastillas', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'manometro', 
                name: 'Manómetro', 
                label: 'Manómetro', 
                inputType: {type: InputType.TEXT}
            },
        ];


    public formFieldsLimpiezaGeneral: ItemInterface<object>[] = 
        [
            {
                field: 'hora_inicio_limpieza_general', 
                name: 'Hora Inicio LIMPIEZA GENERAL', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}
            },
            {
                field: 'hora_fin_limpieza_general', 
                name: 'Hora Fin LIMPIEZA GENERAL', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}
            },
            {
                field: 'horas_limpieza_general', 
                name: 'Horas Totales LIMPIEZA GENERAL', 
                label: 'Horas totales', 
                inputType: {type: InputType.ONLY_TEXT} 
            },
        ];

    public formFieldsRecambiosLuces: ItemInterface<object>[] =
        [
            {
                field: 'luces_R90', 
                name: 'R90 LED', 
                label: 'R90 LED', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'luces_11W', 
                name: '11W Bajo Consumo', 
                label: '11W Bajo Consumo', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'luces_18ww', 
                name: '18ww LED (Tubo Fluoresc.)', 
                label: '18ww LED (Tubo Fluoresc.)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'luces_26W', 
                name: '26W Bajo Consumo (Tubo Fluoresc.)', 
                label: '26W Bajo Consumo (Tubo Fluoresc.)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'luces_14w', 
                name: '14w Tubo Fluorescente', 
                label: '14w Tubo Fluorescente', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'luces_22w', 
                name: '22w LED (Tubo Fluoresc.)', 
                label: '22w LED (Tubo Fluoresc.)', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsRecambiosPapelHigienico: ItemInterface<object>[] =
        [
            {
                field: 'papel_higienico', 
                name: 'Papel Higiénico', 
                label: '', 
                inputType: {type: InputType.TEXT}
            },
        ];
  
    public formFieldsRecambiosRejillas: ItemInterface<object>[] =
        [
            {
                field: 'rejillas_200x500', 
                name: 'Desagües 200x500', 
                label: 'Desagües 200x500', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'rejillas_130x500', 
                name: 'Desagües 130x500', 
                label: 'Desagües 130x500', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'rejillas_transversales_curva_alta', 
                name: 'Transversales P. Curva Alta 22', 
                label: 'Transversales P. Curva Alta 22', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsRecambiosProductosLimpieza: ItemInterface<object>[] =
        [
            {
                field: 'fregasuelos_5l', 
                name: 'Fregasuelos 51', 
                label: 'Fregasuelos 5l', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'escoba_interior', 
                name: 'Escoba Interior', 
                label: 'Escoba Interior', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'escoba_exterior', 
                name: 'Escoba Exterior', 
                label: 'Escoba Exterior', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'recogedor', 
                name: 'Recogedor', 
                label: 'Recogedor', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'palo_escoba', 
                name: 'Palo Escoba', 
                label: 'Palo Escoba', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fregona_algodon', 
                name: 'Fregona Algodón', 
                label: 'Fregona Algodón', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'alcohol_limpieza', 
                name: 'Alcohol Limpieza', 
                label: 'Alcohol Limpieza', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'recogedor_palo', 
                name: 'Recogedor Palo', 
                label: 'Recogedor Palo', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'lejia_5l', 
                name: 'Lejía 51', 
                label: 'Lejía 5l', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'lejia_1l', 
                name: 'Lejía 1l', 
                label: 'Lejía 1l', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'salfuman_5l', 
                name: 'Salfuman 51', 
                label: 'Salfuman 5l', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'salfuman_1l', 
                name: 'Salfuman 1l', 
                label: 'Salfuman 1l', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'amoniaco', 
                name: 'Amoniaco', 
                label: 'Amoniaco', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'limpiacristales', 
                name: 'Limpiacristales', 
                label: 'Limpiacristales', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'antical', 
                name: 'Antical', 
                label: 'Antical', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'abrillantador', 
                name: 'Abrillantador', 
                label: 'Abrillantador', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'saca_de_trapos', 
                name: 'Saca de Trapos', 
                label: 'Saca de Trapos', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'lana_de_roca', 
                name: 'Lana de Roca', 
                label: 'Lana de Roca', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cristalizador', 
                name: 'Cristalizador', 
                label: 'Cristalizador', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'trabajadores_nombres', 
            name: 'Profesional(es)', 
            label: 'Profesional(es)', 
            inputType: {type: InputType.TEXTAREA}
        }, 
    ];
  
    public formFieldsOrdenTrabajo: ItemInterface<object>[] = 
        [  
            {
                field: 'orden_trabajo', 
                name: 'Orden de trabajo', 
                label: 'Orden de trabajo', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];
    public formFieldsObservaciones: ItemInterface<object>[] = 
        [  
            {
                field: 'observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];

    public prioridad = {
        selected: ''
    };

    public cols: ColInterface[] = [
        {field: 'cliente_nombre', header: 'Cliente', filter: ''},
        {field: 'trabajadores_nombres', header: 'Empleado', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'fecha', header: 'Fecha', filter: ''},
        {field: 'hora_inicio_jardin', header: 'Hora Inicio Jardín', filter: ''},
        {field: 'hora_fin_jardin', header: 'Hora Fin Jardín', filter: ''},
        {field: 'horas_jardin', header: 'Horas Jardín', filter: ''},
        {field: 'hora_inicio_piscina', header: 'Hora Inicio Piscina', filter: ''},
        {field: 'hora_fin_piscina', header: 'Hora Fin Piscina', filter: ''},
        {field: 'horas_piscina', header: 'Horas Piscina', filter: ''},
        {field: 'prioridad', header: 'Prioridad/Realizada', filter: ''},
        {field: 'observaciones', header: 'Observaciones', filter: ''}
    ];

    public totalHorasJardin: Moment | string = '00:00';
    public auxTotalHorasJardin: Moment | string = '00:00';
    public totalHorasPiscina: Moment | string = '00:00';
    public auxTotalHorasPiscina: Moment | string = '00:00';

    public fecha: {inicio: string; fin: string} = {
        inicio: '',
        fin: '',
    };
    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.edificios.GET, tareasApi.edificios.DELETE);
  
  
    }

    ngOnInit() {
        if (environment.features.showTareasMto){
            if (this.rolUser !== 'admin'){
                this.hiddenButtonDelete = true;
            }
            this.prioridad.selected = 'No-Realizada';
        }
        this.dashboard.setSubmenuItem(this.menuItemId);
        this.filtering.addFilter((copy: TareaEdificiosModel[] | void) => (copy as  TareaEdificiosModel[]).filter(row => {
            switch (this.prioridad.selected) {
            case 'Realizada':
                return ['Realizada'].includes(row.prioridad ?? '');
            case 'No-Realizada':
                return !['Realizada'].includes(row.prioridad ?? '');
            default:
                return true;
            }
        }));
        /*** 
   *  En el caso de que el usuario no se admin,
   * filtrará por su id de usuario que coincide,
   *  con el id trabajador   
  */
        if ( StorageManager.getUser().rol !== 'admin' ){ 
            this.filtering.addFilter((copy: TareaEdificiosModel[] | void) => (copy as TareaEdificiosModel[]).filter(row => {
            
                if (row.ids_trabajadores != null ) {
              
                    const idTrabajador = StorageManager.getUser().id;
                    const listaTrabajadores = (row.ids_trabajadores as string).split(';');
                    if ( listaTrabajadores.includes(idTrabajador)) {
                        return true;
                    }
              
                }
                return false;
            }));
        }
    
        /*** 
       *  Fin de filtrar por id_usuario = trabajador,
       *   
      */
        this.filtering.addFilter((copy: TareaEdificiosModel[] | void) => (copy as TareaEdificiosModel[]).filter(row => {
            if (this.fecha.inicio && this.fecha.fin && row.fecha) {
    
                const arrayFecha = row.fecha.toString().split('/');
                const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];
    
                if (
                    (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                    (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }));
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', this.pathToForm]);
        }
    }
  
    public edit(data: TareaEdificiosModel) {
        //console.log('data',data);
      
        this.router.navigate(['dashboard', 'editar-edificio', data.id]);
    }
  
    public duplicate(data: TareaEdificiosModel) {
        this.router.navigate(['dashboard', 'duplicar-edificio', data.id]);
    }  

    public override deleteRegister() {
        if (this.model['id']) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }  

    public override show(modal: string, data: TareaEdificiosModel) {
        let x;
    
        const trueFields = [
            'barredera',
            'cepillado',
            'limpieza_red',
            'rascado_linea_flotacion',
            'lavado_enjuague_cesti',
            'limpieza_duchas',
            'limpieza_skimmer',
            'nivel_vaso',
            'limpieza_cuarto_depuradora'
        ];
    
        trueFields.forEach( f => {
            data[f] = TRUTHY_VALUES.includes(data[f]?.toString() ?? '');
        });

        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }

    

    }  

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242'
                    },  
                };
            }
            else if (value === 'Realizada'){
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },  
                };
            }
        }

        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
    

        if (col.field === 'mantenimiento') {
            if ( TRUTHY_VALUES.includes(value) ) {
                return 'Creación';
            } else {
                return 'Mantemiento';
            }
        }
  
        return value;
    }
  
    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }

    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'prioridad') {
            this.prioridad.selected = this.prioridad.selected === value ? '' : value;
        } 

        this.filtering.filter();
    }

    public async downloadCsv() {
  
        const arrayFormFields: ItemInterface<object>[][] = [
            this.formFields,
            this.formFieldsJardin,
            this.formFieldsPiscinaHoras,
            this.formFieldsPiscinaTareas01,
            this.formFieldsPiscinaTareas02,
            this.formFieldsPiscinaTareas03,
            this.formFieldsPiscinaProductos01,
            this.formFieldsPiscinaProductos02,
            this.formFieldsPiscinaProductos03,
            this.formFieldsPiscinaRecambios,
            this.formFieldsLimpiezaGeneral,
            this.formFieldsRecambiosLuces,
            this.formFieldsRecambiosPapelHigienico,
            this.formFieldsRecambiosRejillas,
            this.formFieldsRecambiosProductosLimpieza,
            this.formFieldsTrabajadores,
            this.formFieldsObservaciones,
        ];
  
        const csvContent = createCsv(arrayFormFields, this.filtering.value, 'observaciones' );
  
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Edificios.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
  
    }

    public override updateFooter() {
        this.sum();
    }
  
    public override sum() {
        this.totalHorasJardin = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHorasJardin = moment(new Date('October 1, 2014 00:00:00'));
        let contadorJardin = 0;
        (this.filtering.value || []).forEach((tareas, index ) => {
  
            if ( tareas.horas_jardin != null ) {
     
                if (tareas.horas_jardin.length > 0) {
                    contadorJardin++;
                    const horaMinuto = (tareas.horas_jardin as string).split(':');
                    this.totalHorasJardin = moment(this.totalHorasJardin).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }
  
            if ( index + 1 >= (this.filtering.value as TareaEdificiosModel[]).length ){
    
                let minutos = moment(this.totalHorasJardin).format('mm');
        
        
                let differencia = moment(this.totalHorasJardin).diff(this.auxTotalHorasJardin);
        
                if ( minutos.toString().length === 0) {
                    minutos = '00';
                }
  
                if ( minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }
                differencia = differencia / (1000 * 60 * 60);
                differencia = Math.abs(Math.floor(differencia));
  
                this.totalHorasJardin = differencia + ':' + minutos;
        
                if ( contadorJardin === 0){
                    this.totalHorasJardin = '00:00';
                }
  
            }
    
      
        });
  
        if ( contadorJardin === 0){
            this.totalHorasJardin = '00:00';
        }

        this.totalHorasPiscina = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHorasPiscina = moment(new Date('October 1, 2014 00:00:00'));
        let contadorPiscina = 0;
        (this.filtering.value || []).forEach((tareas, index ) => {
  
            if ( tareas.horas_piscina != null ) {
     
                if (tareas.horas_piscina.length > 0) {
                    contadorPiscina++;
                    const horaMinuto = (tareas.horas_piscina as string).split(':');
                    this.totalHorasPiscina = moment(this.totalHorasPiscina).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }
  
            if ( index + 1 >= (this.filtering.value as TareaEdificiosModel[]).length ){
    
                let minutos = moment(this.totalHorasPiscina).format('mm');
        
        
                let differencia = moment(this.totalHorasPiscina).diff(this.auxTotalHorasPiscina);
        
                if ( minutos.toString().length === 0) {
                    minutos = '00';
                }
  
                if ( minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }
                differencia = differencia / (1000 * 60 * 60);
                differencia = Math.abs(Math.floor(differencia));
  
                this.totalHorasPiscina = differencia + ':' + minutos;
            }      
        });

        if ( contadorJardin === 0){
            this.totalHorasJardin = '00:00';
        }

        if ( contadorPiscina === 0){
            this.totalHorasPiscina = '00:00';
        }
    
        this.filtering.filter();
    }
  
    getFechaInicio( event: string) {
        this.fecha.inicio = event;
        this.filtering.filter();
        this.updateFooter();
    
    }
    getFechaFin( event: string) {
        this.fecha.fin = event;
        this.filtering.filter();
        this.updateFooter();
    }
  
    ngAfterViewInit() {
        this.tareasApi.edificios.GET.response(() => {
            this.updateFooter();
            this.sum();
        });
    }
  
}

import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {list} from '../../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import {environment} from '../../../../../../../environments/environment';
import {StorageManager} from '../../../../../../common/storage-manager.class';
import { checkIfRolAdmin } from 'src/app/common/check-role';

import type { ItemInterface, TypeAheadInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { TareaFitoModel } from 'src/app/models/tareas/tarea-fito.model';
import { ClienteModel } from 'src/app/models/cliente.model';
export class FitosanitariosFormDefinition {
    
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public hasClients = environment.features.hasClients;
    public showClimatologia = environment.features.showClimatologia;
    public hasFincas = environment.features.hasFincas;
    public mostrarTodosTratamientos = environment.features.mostrarTodosTratamientos;
    public showRegistroTratamiento = environment.features.showRegistroTratamiento;
    public showPhCaldo = environment.features.showPhCaldo;
    public tareasFitoFilterBySector = environment.features.tareasFitoFilterBySector;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelasName = environment.features.parcelasName;
    public showTareasFincas = environment.features.showTareasFincas;
    public showTareasSector = environment.features.showTareasSector;
    public showTareasFitoSectorMultiple = environment.features.showTareasFitoSectorMultiple;
    public showTareasParcela = environment.features.showTareasParcela;
    public showFitoMomentoAplicacion = environment.features.showFitoMomentoAplicacion;
    public showFitoCosteTratamiento = environment.features.showFitoCosteTratamiento;
    public showFitoTipoTratamiento = environment.features.showFitoTipoTratamiento;
    public showAplicadoSwitch = environment.features.showAplicadoSwitch;
    public showTipoPlaga = environment.features.showTipoPlaga;
    public showMotivoFito = environment.features.showMotivoFito;
    public showTareasFitoObservaciones = environment.features.showTareasFitoObservaciones;
    public showDesplazamientoObra = environment.features.showDesplazamientoObra;
    public showTareasFitoSoleado = environment.features.showTareasFitoSoleado;
    public showTareasFitoCarretera = environment.features.showTareasFitoCarretera;
    public showTareasFitoPuntoKM = environment.features.showTareasFitoPuntoKM;
    public showTareasFitoLitrosAplicados = environment.features.showTareasFitoLitrosAplicados;
    public showTareasFitoHoras = environment.features.showTareasFitoHoras;
    public timeStartStopTareas = environment.features.timeStartStopTareas;
    public showTareasFitoMotivoJustificacion = environment.features.showTareasFitoMotivoJustificacion;
    public showFitoValidado = environment.features.showFitoValidado
        ? (StorageManager.getUser().rol === 'admin')
        : false;
    public showTareasFitoIT = environment.features.showTareasFitoIT;
    public showTareasFitoLote = environment.features.showTareasFitoLote;
    public showTareasFitoMochila = environment.features.showTareasFitoMochila;
    public showTareasFitoAplicacion = environment.features.showTareasFitoAplicacion;
    public userCanSeeAllRecords = environment.features.userCanSeeAllRecords
        ? true
        : (StorageManager.getUser().rol === 'admin');
    public userCanSeeClientes = environment.features.userCanSeeClientes
        ? true
        : (StorageManager.getUser().rol === 'admin');

    public showTractorVelocidad = environment.features.showTractorVelocidad;
    public showTractorMarcha = environment.features.showTractorMarcha;
    public showTractorBoquillas = environment.features.showTractorBoquillas;
    public showTractorRPM = environment.features.showTractorRPM;
    public showTractorPresion = environment.features.showTractorPresion;
    public showTractorKmRecorridos = environment.features.showTractorKmRecorridos;
    public showTractorConfig = (
        this.showTractorMarcha ||
        this.showTractorPresion ||
        this.showTractorRPM ||
        this.showTractorBoquillas ||
        this.showTractorVelocidad ||
        this.showTractorKmRecorridos
    );
    public showTareasFitoPuntoKm = environment.features.showTareasFitoPuntoKm;
    public showTareasProcura = environment.features.showTareasProcura;    
    public showMomentoAplicacion = environment.features.showMomentoAplicacion;    
    public textoSuperficie = environment.features.textoSuperficie;
    public showClientesNotas = environment.features.showClientesNotas;
    public showTareasFitoMaquinarias = environment.features.showTareasFitoMaquinarias;
    
    public rol = StorageManager.getUser().rol;
    // Para los usuarios de user debes especificar la horas de inicio y fin para el cliente AVA
    public activateRequireHourForRolUser = this.appName === 'ava' ? this.rol !== 'admin' ? true : false : false;
    public lastFieldChanged = '';

    public requestType: FormRequestTypes = FormRequestTypes.CREATE;

    public fincas: TypeAheadInterface<FincasModel> = list();
    public sectores: TypeAheadInterface<SectorModel | SectorModel[]> = list();
    public parcelas: TypeAheadInterface<ParcelasModel | ParcelasModel[]> = list();
    public cultivos: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public trabajadores: TypeAheadInterface<MantenimientoModel> = list();
    public usuarios: TypeAheadInterface<UserModel> = list();
    public maquinarias: TypeAheadInterface<MaquinariaModel> = list();
    public tratamientos: TypeAheadInterface<TratamientosModel> = list();
    public justificaciones: TypeAheadInterface<string> = list();
    public rpmValues: TypeAheadInterface<string> = list(['...', null], '1000', '1100', '1200', '1300', '1400', 
        '1500', '1600', '1700', '1800', '1900', '2000');
    public presionLaplana: TypeAheadInterface<string> = list(
        ['...', null], '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23',
        '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41',
        '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60');
    public presionAll: TypeAheadInterface<string> = list(['...', null], '3', '4', '8', '9', '10', '11', '12',
        '13', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44',
        '45', '46', '47', '48', '49', '50');
    public presionHerbicida: TypeAheadInterface<string> = list(['...', null], '3', '4');
    public presionTurbo: TypeAheadInterface<string> = list(['...', null], '8', '9', '10', '11', '12', '13');
    public presionDisparador: TypeAheadInterface<string> = list(['...', null], '30', '31', '32', '33', '34',
        '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50');

    public ph_caldo: TypeAheadInterface<string> = list(['...', null], '6,5', '7', '7,5', '8', '8,5');
    public eficacia: TypeAheadInterface<string> = list(['...', null], ['Buena', '1'], ['Regular', '2'], ['Mala', '3']);
    public clientes: TypeAheadInterface<ClienteModel> = list();
    public mano_obra: TypeAheadInterface<string> = list(['...', null], '0', '0.02', '0.03', '0.04', '0.05', '0.06');
    public desplazamiento: TypeAheadInterface<string> = list(
        ['...', null], ['10 €', '10'], ['15 €', '15'], ['20 €', '20'], ['25 €', '25']
    );
    public tipo_plaga: TypeAheadInterface<string> = list(['...', null], 
        'Ácaros', 
        'Aguado', 
        'Alternaria', 
        'Amarre fruto', 
        'Antracnosis', 
        'Caracoles', 
        'Carencias', 
        'Ceratitis', 
        'Conservación', 
        'Cotonet', 
        'Cuajado', 
        'Diaspinos', 
        'Engorde', 
        'Estímulo',
        'Gusano blanco',
        'Gusano de alambre',
        'Gusano gris',
        'Heladas',
        'Hormigas', 
        'Hongos',
        'Malas hierbas', 
        'Mejorante', 
        'Melaza', 
        'Minador', 
        'Mosca blanca', 
        'Nutrición', 
        'Phytophtora', 
        'Pulgones', 
        'Sciara',
        'Trips', 
        'Enfermedades tronco');
    public motivo: TypeAheadInterface<string> = list(['...', null], 
        '>60% pet. Caidos', 
        'Acidificante', 
        'Aumento brotación', 
        'Bajo calibre', 
        'Capturas en trampas',
        'Condiciones climáticas', 
        'Defoliación intensa', 
        'Dispersante melaza', 
        'Fruta picada', 
        'Mayor % frutos cuajados', 
        'Mejora aplicación', 
        'Mejora calidad fruto', 
        'Muestreo plaga', 
        'Presencia', 
        'Presencia en fruto', 
        'Presencia en fruto en campaña anterior',
        'Prevención', 
        'Ramas secas', 
        'Síntomas visuales', 
        '2ªG Diaspinos', 
        'Aviso oficial de tratamiento de diaspinos', 
        'Heridas en la base del tronco');
    
    public momentosAplicacion: TypeAheadInterface<string> = list(['...', null], 
        'Brotación',
        'Cambio Color',
        'Cuajado',
        'Engorde Frutos',
        'Floración',
        'Parada Invernal'
    );

    public tecnica: TypeAheadInterface<string> = list(
        ['...', null],
        ['Inyección', '1'],
        ['Inmersión', '2'],
        ['Espolvoreo', '3'],
        ['Granulado', '4'],
        ['Pulverización', '5'],
        ['Atomización', '6'],
        ['Cebo', '7'],
        ['Agua de riego', '8'],
        ['Otros', '0']
    );

    public tiposTratamiento: TypeAheadInterface<string> = list(['...', null], 'Foliar', 'Radicular');
    public estadoTarea: TypeAheadInterface<string> = list(['...', null], 'Orden de Trabajo', 'Parte de Trabajo');

    public model: TareaFitoModel = {};

    public ids_productos = '';

    public plan_abonado_list = list([]);
    public tipo_fertilizacion = list([]);

    public formFieldsTiempo: ItemInterface<object>[] = [
        {
            field: 'fecha_inicio',
            label: (environment.appName === 'armentia') ? 'Fecha inicio' : 'Fecha Receta',
            inputType: {type: InputType.CALENDAR},
            visible: !environment.features.showTareasMto
        },
    ];
    
    public formFieldsKM: ItemInterface<object>[] = [
        {
            field: 'punto_km',
            label: 'Punto Km',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTareasFitoPuntoKM && !this.showTareasFitoPuntoKm
        },
        {
            field: 'punto_km_inicio',
            label: 'Punto Km inicial',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTareasFitoPuntoKm
        },
        {
            field: 'punto_km',
            label: 'Punto Km final',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTareasFitoPuntoKm
        }
    ];
    
    public formFieldsLugar: ItemInterface<object>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            required: true,
            filter: (it: { value: FincasModel }) => {
                if (!this.hasClients  || !this.userCanSeeClientes) {
                    return true;
                } else if (StorageManager.getClient()) {
                    return it.value.id_cliente === (StorageManager.getClient() || {}).id;
                }
                return true;
            },
            visible: this.hasFincas && this.showTareasFincas
        },
        {
            field: 'id_sector',
            label: this.sectorName,
            inputType: {type: this.showTareasFitoSectorMultiple ? InputType.DROPDOWN_MULTISELECT_SEARCH : InputType.DROPDOWN},
            multiSelect: this.showTareasFitoSectorMultiple,
            values: this.sectores,
            valuePrimaryKey: 'id',
            filter: (it: { value: SectorModel}) => {
                if (this.hasFincas && this.showTareasFincas) {
                    return it.value.id_finca === (this.fincas.selected || {}).id;
                }
                return true;
            },
            visible: this.showTareasSector,
        },
        {
            field: 'ids_parcelas' ,
            label: this.parcelasName,
            inputType: {type: InputType.DROPDOWN_MULTISELECT_SEARCH},
            values: this.parcelas,
            valuePrimaryKey: 'id',
            multiSelect: true,
            canSelectAll: true,
            visible: this.showTareasParcela,
            filter: (it: { value: ParcelasModel }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                if (this.showTareasFitoSectorMultiple) {
                    if ((this.sectores.selected as SectorModel[])[0]) {   
                        return (
                            this.sectores.selected as SectorModel[]
                        ).map((sector: SectorModel) => sector.id).includes(it.value.id_sector ?? '');
                    }
                } else {
                    if (this.sectores.selected) {   
                        return it.value.id_sector === (this.sectores.selected as SectorModel).id;
                    }
                }
                return it.value.id_finca === (this.fincas.selected || {}).id;
            },
            required: true
        }
    ];

    public formFieldsTratamiento: ItemInterface<object>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Profesionales',
            inputType: {type: InputType.DROPDOWN_MULTISELECT_SEARCH},
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: MantenimientoModel}) => {
                if (it.value.activo !== '1') {
                    return false;
                }

                if (this.applicationType === 'sequiador') {
                    let aplicadorValido = false;
                    if (it.value.tipo_usuario === 'aplicador') {
                        (this.parcelas.selected as ParcelasModel[]).forEach((element: ParcelasModel) => {
                            const aplicadores = element.aplicadores?.split(';') ?? [];
                            aplicadores.forEach(aplicador => {
                                if (aplicador === it.value.id_usuario) {
                                    aplicadorValido = true;
                                }
                            });
    
                        });
                    } else {
                        aplicadorValido = true;
                    }

                    return aplicadorValido;
                } else {
                    return true;
                }
            }
        },
        {
            field: 'ids_asignados',
            label: 'Asignador',
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true,
            canSelectAll: true,
            valuePrimaryKey: 'id',
            values: this.usuarios,
            visible: (environment.features.showAssignUserTasksFertilizante || environment.features.showAssignUserInAllTasks) && 
                StorageManager.getUser().rol === 'admin',
            required: true
        },
        /* Los datos provienen de:
            - Los aplicadores asignados a la parcel·la por el comunero.
            - El propio comunero.
        */
        // {
        //     field: 'ids_trabajadores',
        //     label: 'Profesionales',
        //     inputType: {type: InputType.MULTISELECT},
        //     values: this.trabajadores,
        //     valuePrimaryKey: 'id',
        //     multiSelect: true,
        //     visible: this.appName === 'sequiador'
        // },
        {
            field: 'momento_aplicacion',
            label: 'Momento de aplicación',
            inputType: {type: InputType.DROPDOWN},
            values: this.momentosAplicacion,
            visible: this.showFitoMomentoAplicacion
        },
        {
            field: 'coste_tratamiento',
            label: 'Coste (€)',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.showFitoCosteTratamiento
        },
        {
            field: 'tipo_tratamiento',
            label: 'Tipo de tratamiento',
            inputType: {type: InputType.DROPDOWN},
            values: this.tiposTratamiento,
            visible: this.showFitoTipoTratamiento
        },
        {
            field: 'id_tratamiento',
            label: 'Tratamientos',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.tratamientos,
            valuePrimaryKey: 'id',
            filter: (it: { value: TratamientosModel }) => {
                if (this.mostrarTodosTratamientos) {
                    return true;
                } else {
                    if ( 
                        it.value.nombre == null || 
                        (it.value.nombre as string).localeCompare('null') === 0 || 
                        (it.value.nombre as string).length === 0 ) {
                        return false;
                    }
                    /* if (![1, '1', true, 'true'].includes(it.value.activo)) {
                        return false;
                    } else  */
                    if (this.appName === 'abonosfolques' || this.appName === 'agricar'){
                        return true;
                    }  else if (!this.hasClients || (this.requestType === FormRequestTypes.DUPLICATE) || !this.userCanSeeClientes) {
                        return true;
                    } else if (this.showTareasFitoCarretera && this.model.id_cliente) {
                        return it.value.id_cliente === this.model.id_cliente;
                    } else if (this.fincas.selected) {
                        return it.value.id_cliente === (this.fincas.selected || {}).id_cliente;
                    } else if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else if (StorageManager.getClient() && StorageManager.getClient().id) {
                        return it.value.id_cliente === (StorageManager.getClient() || {}).id;
                    }
                    
                    return true;
                }

            }
        },
        {
            field: 'ph_caldo',
            label: 'pH caldo',
            inputType: {type: InputType.DROPDOWN},
            values: this.ph_caldo,
            visible: this.showPhCaldo
        },
        {
            field: 'litros',
            label: this.appName === 'agrosalvi' ? 'Volumen (gr o litros agua)' : 
                this.appName === 'onda' ? 'Litros' : this.appName === 'hernandorena' ? 'Caldo (litros)' : 
                    this.appName !== 'innovia' ? 'Litros/' + this.textoSuperficie + (this.appName === 'vginer' ? ' (recomendación)' : '') : 
                        'Litros Caldo',
            inputType: {type: environment.features.showTareasMto ? InputType.EDIT_NUMERIC : InputType.EDIT_TEXT},
            visible: this.appName !== 'momparler'
        },
        {
            field: 'litros',
            label: 'Litros/m²',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'momparler'
        },
        {
            field: 'mochila',
            label: 'Es mochila',
            inputType: {type: InputType.SWITCH},
            visible: this.showTareasFitoMochila
        },
        {
            field: 'sup_tratada',
            label: 'Superficie tratada',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'T', 'P', 'F'),
            visible: this.appName === 'pyf'
        },        
        {
            field: 'orden_asesor',
            label: 'Orden asesor',
            inputType: {type: InputType.SWITCH},
            visible: this.appName === 'pyf'
        },
        {
            field: 'aplicacion',
            label: 'Modo de aplicación',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Arcén', 'Medianera'),
            visible: this.showTareasFitoAplicacion
        },
        {
            field: 'procura',
            label: 'Procura',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: list(['...', null], 'Procura', 'Media procura', 'Libre'),
            visible: this.showTareasProcura
        },        
        {field: 'tipo_plaga', label: 'Tipo de plaga', inputType: {type: InputType.DROPDOWN}, values: this.tipo_plaga,
            visible: this.showTipoPlaga},
        {
            field: 'litros_totales',
            label: 'Litros Totales',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.appName === 'gragon'
        },    
        {field: 'motivo', label: 'Motivo', inputType: {type: InputType.DROPDOWN}, values: this.motivo,
            visible: this.showMotivoFito && this.appName !== 'gragon'},
        {
            field: 'codigo_justificacion',
            label: 'Justificación de la Actuación',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.justificaciones,
            valuePrimaryKey: 'codigo'
        },
        {
            field: 'desplazamiento',
            label: 'Desplazamiento',
            inputType: {type: InputType.DROPDOWN},
            values: this.desplazamiento,
            visible: this.showDesplazamientoObra
        },
        {
            field: 'mano_obra',
            label: 'Mano Obra',
            inputType: {type: InputType.DROPDOWN},
            values: this.mano_obra,
            visible: this.showDesplazamientoObra
        },
        {
            field: 'observaciones',
            label: 'Recomendación',
            inputType: {type: InputType.EDIT_TEXTAREA},
            visible: this.showTareasFitoObservaciones,
            audio: true
        },
        {
            field: 'momento_aplicacion',
            label: 'Momento de aplicación',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.showMomentoAplicacion
        },
        {
            field: 'limpieza_epis',
            label: 'Limpieza de EPIs',
            inputType: {type: InputType.SWITCH},
            visible: this.appName === 'vginer'
        },
        {
            field: 'dia_limpieza',
            label: 'Día de limpieza',
            inputType: {type: InputType.CALENDAR},
            visible: this.appName === 'vginer'
        },
        {
            field: 'it',
            label: 'Instrucción técnica',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTareasFitoIT
        },
        {
            field: 'lote',
            label: 'Lote',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTareasFitoLote
        },
        {
            field: 'tecnica',
            label: 'Técnica',
            inputType: {type: InputType.DROPDOWN},
            values: this.tecnica,
            visible: environment.appName === 'pyf'
        },        
        {
            field: 'notas_cliente',
            label: 'Notas cliente',
            inputType: {type: InputType.TEXTAREA},
            visible: this.showClientesNotas
        },
        {
            field: 'estado_tarea', 
            label: 'Estado de la Tarea', 
            inputType: {type: InputType.DROPDOWN},
            values: this.estadoTarea,
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'empresa_pagadora', 
            label: 'Empresa Pagadora', 
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
    ];

    public formFieldsTractor: ItemInterface<object>[] = [
        {
            field:  'id_maquinaria',
            label: 'Maquinaria',
            inputType: {
                type:  (this.appName === 'bravosol' || this.appName === 'agrosalvi') ? InputType.MULTISELECT : InputType.DROPDOWN_SEARCH  
            },
            multiSelect: this.appName === 'bravosol' || this.appName === 'agrosalvi',
            values: this.maquinarias,
            valuePrimaryKey: 'id',
            visible: this.showTareasFitoMaquinarias,
            filter: (it: { value: MaquinariaModel }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                
                if (this.appName === 'pyf') {
                    if (StorageManager.getClient()?.id) {
                        return it.value.id_cliente === StorageManager.getClient().id;
                    } else {
                        return true;
                    }
                }

                if (this.applicationType === 'sequiador') {
                    let aplicadorValido = false;
                    if (it.value.tipo_usuario === 'aplicador') {
                        (this.parcelas.selected as ParcelasModel[]).forEach((element: ParcelasModel) => {
                            const aplicadores = element.aplicadores?.split(';') ?? [];
                            aplicadores.forEach(aplicador => {
                                if (aplicador === it.value.id_usuario) {
                                    aplicadorValido = true;
                                }
                            });
    
                        });
                    } else {
                        aplicadorValido = true;
                    }

                    return aplicadorValido;
                } else {
                    return true;
                }
            }
        },
        {
            field: 'velocidad',
            label: 'Velocidad\xa0(Km/h)',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.showTractorVelocidad},
        {
            field: 'marcha',
            label: 'Marcha',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTractorMarcha
        },
        {
            field: 'rpm',
            label: 'RPM',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.rpmValues,
            visible: this.showTractorRPM
        },
        {
            field: 'presion',
            label: 'Presión\xa0(atm-bar)',
            inputType: {type: (['simat', 'laplana'].includes(this.appName)) ? InputType.DROPDOWN : InputType.EDIT_NUMERIC},
            values: ['simat', 'laplana'].includes(this.appName) ? this.getDefaultPresionValues() : {},
            visible: this.showTractorPresion
        },
        {
            field: 'boquillas',
            label: 'Boquillas',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTractorBoquillas
        },
        {
            field: 'km_recorridos',
            label: 'Km recorridos',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTractorKmRecorridos
        }
    ];

    public formFieldsInnovia: ItemInterface<object>[] = [
        {
            field: 'litros1', 
            label: 'Litros ', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'litros2', 
            label: 'Litros ', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'litros3', 
            label: 'Litros ', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },

    ];

    public formFieldsFinal: ItemInterface<object>[] = [
        {
            field: 'hora_inicio', 
            label: 'Hora Inicio', 
            inputType: {type: InputType.TIME},
            visible: environment.features.showTareasMto || this.showTareasFitoHoras || this.timeStartStopTareas,
            required: this.activateRequireHourForRolUser
        },
        {
            field: 'hora_fin', 
            label: 'Hora Fin', 
            inputType: {type: InputType.TIME}, 
            visible: environment.features.showTareasMto || this.showTareasFitoHoras || this.timeStartStopTareas,
            required: this.activateRequireHourForRolUser
        },          
        {
            field: 'horas_totales', 
            label: 'Horas', 
            inputType: {type: InputType.ONLY_TEXT}, 
            visible: environment.features.showTareasMto || environment.features.tareasHorasTotales || this.showTareasFitoHoras
        },
        {
            field: 'fecha_fin', 
            label: (environment.appName === 'armentia') ? 'Fecha fin' : 'Fecha Aplicación', 
            inputType: {type: InputType.CALENDAR},
            required: this.appName === 'agrosalvi' && this.rol === 'user'
        },
        {
            field: 'fin_fecha', 
            label: 'Fecha fin', 
            inputType: {type: InputType.CALENDAR},
            visible: this.appName === 'fotoagricultura'
        },
        {
            field: 'hora_fin',
            label: 'Hora Fin',
            inputType: {type: InputType.TIME},
            visible: false
        },
        {
            field: 'fecha_recoleccion',
            label: 'Fecha Recolección',
            inputType: {type: InputType.CALENDAR},
            visible: this.appName === 'vginer'
        },
        {
            field: 'eficacia',
            label: 'Eficacia del Tratamiento',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.eficacia,
            visible: true,
            required: this.appName === 'agrosalvi' && this.rol === 'user'
        },
        {
            field: 'aplicado', 
            label: 'Aplicado', 
            inputType: {type: InputType.SWITCH}, 
            visible: (this.appName === 'agrosalvi' && this.rol !== 'user') || this.showAplicadoSwitch

        },
        { 
            field: 'horas_totales', 
            label: 'Horas', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.appName === 'fotoagricultura' 
        },
        {
            field: 'validado', 
            label: 'Validado', 
            inputType: {type: InputType.SWITCH}, 
            visible: this.showFitoValidado && (this.rol === 'admin')
        },
        {
            field: 'soleado',
            label: 'Soleado',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Cubierto', 'Semicubierto', 'Despejado'),
            visible: this.showTareasFitoSoleado
        },
        {
            field: 'cubas_totales',
            label: 'Cubas totales',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'vginer'
        },
        {
            field: 'litros_aplicados',
            label: this.appName === 'ava' ? 'Vol. Caldo Gastado' : 'Litros aplicados',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTareasFitoLitrosAplicados
        },
        {
            field: 'litros_aplicados_ha',
            label: 'Litros aplicados/Ha',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFitoLitrosAplicados && !environment.features.showCostes
        },
        {
            field: 'facturado',
            label: 'Facturado',
            inputType: {type: InputType.SWITCH},
            visible: this.appName === 'agrosalvi'
        },
        {
            field: 'factura_albaran',
            label: 'Factura/Albarán',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'agrosalvi'
        }
    ];

    public formFieldsClima: ItemInterface<object>[] = [
        {
            field: 'temperatura',
            label: 'Temperatura ℃',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'hr',
            label: this.appName === 'torrepacheco' ? 'Humedad %' : 'Humedad R.', //'H.\xa0R
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'viento',
            label: 'Viento Km/h',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'precip',
            label: 'Precipit. L/m²',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'clima',
            label: 'Clima',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Cubierto', 'Semidespejado', 'Despejado'),
            visible: this.appName === 'vginer'
        },
        {
            field: 'climatologia',
            label: '',
            inputType: {type: InputType.SELECT_BUTTON},
            values: list( ['Obtener clima', null] ),
            visible: this.showClimatologia
        },
    ];

    public formFieldsMezclasSobrantes: ItemInterface<object>[] = [
        {
            field: 'cubeto_evaporacion',
            label: 'Cubeto evaporación',
            inputType: {type: InputType.SWITCH}
        },
        {
            field: 'punto_carga',
            label: 'Punto carga',
            inputType: {type: InputType.SWITCH}
        },
        {
            field: 'litros_sobrante',
            label: 'Litros caldo sobrante',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'dia_tratamiento',
            label: 'Día tratamiento',
            inputType: {type: InputType.CALENDAR}
        },
    ];

    public formFieldsOrdenTrabajo: ItemInterface<object>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA },
        },
    ];

    public formFieldsObs: ItemInterface<object>[] = [
        { 
            field: 'observaciones', 
            label: 'Observaciones', 
            inputType: { type: InputType.EDIT_TEXTAREA } 
        },
    ];

    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }

    public getDefaultPresionValues() {
        switch (this.appName) {
        case 'laplana':
            return this.presionLaplana;
        default:
            return this.presionAll;
        }
    }
}
import {Component} from '@angular/core';
import {BaseView} from '../../../base-view';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {Filtering} from '../../../../service/filtering/filtering';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Utils} from '../../../../common/utils';
import { environment } from 'src/environments/environment';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TareaFitoModel } from 'src/app/models/tareas/tarea-fito.model';

const TRUTHY_VALUES = [1, '1', true, 'true'];
@Component({
    selector: 'app-costes-fitosanitarios',
    templateUrl: './costes-fitosanitarios.component.html',
    styleUrls: ['./costes-fitosanitarios.component.scss']
})
export class CostesFitosanitariosComponent extends BaseView<TareaFitoModel> implements OnInit, OnDestroy {
    public appName = environment.appName;

    public getRequest: RequestHandler<TareaFitoModel[]> = this.tareasApi.fitosanitariosGET;
    public deleteRequest: RequestHandler<TareaFitoModel> = this.tareasApi.fitosanitariosDELETE;
    public menuItemId = 'costes-fitosanitarios';

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showTareasFitoCsv = environment.features.showCostesTareasFitoCsv;

    public override canShow: Record<string, boolean> = {};

    public override model = {} as TareaFitoModel;
    public ps = 0;

    public mostrarTratamientosAplicados = true;
    public override filtering: Filtering<TareaFitoModel> = new Filtering<TareaFitoModel>();

    public distributionOrder = [4];

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca', 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sectores_nombres', 
            label: 'Sector', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'parcelas_nombres', 
            label: 'Parcela', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha_fin', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'tratamiento_nombre', 
            label: 'Tratamientos', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'maquinaria', 
            label: 'Maquinaria', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'litros', 
            label: 'Litros', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'hernandorena'
        },
        {
            field: 'superficie_cultivada', 
            label: 'Superficie', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'hernandorena'
        },
        {
            field: 'dosis', 
            label: 'Dosis', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'hernandorena'
        },
        {
            field: 'total_dosis', 
            label: 'Dosis', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'hernandorena'
        },
        {
            field: 'eficacia', 
            label: 'Eficacia', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'aplicados', 
            label: 'Aplicado', 
            inputType: {type: InputType.RO_SWITCH}
        },
    ];

    public cols: ColInterface[] = [
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'sectores_nombres', header: 'Sector', filter: ''},
        {field: 'tratamiento_nombre', header: 'Tratamientos', filter: '', width: '180px'},
        {field: 'maquinaria', header: 'Maquinaria', filter: ''},
        {field: 'fecha_fin', header: 'Fecha', filter: ''},
        {field: 'eficacia', header: 'Eficacia', filter: ''},
        {field: 'litros', header: 'Litros', filter: '', visible: this.appName !== 'hernandorena'},
        {field: 'superficie_cultivada', header: 'Superficie', filter: '', visible: this.appName === 'hernandorena'},
        {field: 'dosis', header: 'Dosis', filter: '', visible: this.appName === 'hernandorena'},
        {field: 'total_dosis', header: 'Total', filter: '', visible: this.appName === 'hernandorena'},
        {field: 'coste', header: 'Costes', filter: '', footer: '0'}
    ];

    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService) {
        super(dashboard, tareasApi.fitosanitariosGET);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);

        this.filtering.addFilter((copy: TareaFitoModel[] | void) => {
            this.updateFooter();
            return (copy as TareaFitoModel[]).filter(row =>
                row.aplicado === (this.mostrarTratamientosAplicados ? '1' : '0')
            );
        });

        this.filtering.addFilter((data: TareaFitoModel[] | void) => 
            (data as TareaFitoModel[]).sort((a: TareaFitoModel, b: TareaFitoModel) =>
                parseInt(a.id ?? '', 10) > parseInt(b.id ?? '', 10) ? -1 : 1
            ));

    }

    public filtrarTratamientos() {
        this.filtering.filter();
    }

    public moneyFormat(input: number) {
        return Utils.decimalFormat(input, 2);
    }

    public cuantityFormat(input: number) {
        return Utils.decimalFormat(input, 1);
    }

    public override show(modal: string, data: TareaFitoModel) {
        let x;

        data.aplicado = TRUTHY_VALUES.includes(data.aplicado ?? '');

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public override ngOnDestroy() {
        Utils.unsuscribe([
            this.tareasApi.fitosanitariosGET,
        ]);
        Utils.unsuscribe([
            this.tareasApi.fitosanitariosDELETE
        ]);
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filtering.value || []).forEach(it => {
            const coste = it;
            sum += coste[field] as number;
        });

        this.cols.forEach(it => {
            if (it.field === field) {
                it['footer'] = sum + '';
            }
        });
    }

    public override updateFooter() {
        ['coste'].forEach(it => this.sum(it));
    }

    public globalConditionalStyle(value: string): ColStylesInterface {
        if (Utils.isNumber(value)) {
            return {
                textAlign: 'right',
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface): string {
        if (Utils.isNumber(value) && !['litros', 'sector'].includes(col.field)) {
            return Utils.decimalFormat(value, 2);
        } else if (Utils.isNumber(value) && col.field === 'litros') {
            return Utils.decimalFormat(value, 0, '.', ',');
        } else {
            return value;
        }
    }

}

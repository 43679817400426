import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import { StorageManager } from '../../../../common/storage-manager.class';
import { createCsv } from 'src/app/common/createCsv';
import { Utils } from 'src/app/common/utils';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { Filtering } from 'src/app/service/filtering/filtering';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { BaseView } from 'src/app/view/base-view';
import { environment } from 'src/environments/environment';

import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { EcorregimenModel } from 'src/app/models/tareas/ecorregimen.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-ecorregimes',
    templateUrl: './ecorregimes.component.html',
    styleUrls: ['./ecorregimes.component.css']
})
export class EcorregimesComponent extends BaseView<EcorregimenModel>  {
    public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public rolUser = StorageManager.getUser().rol;
    public userCanSeeClientes = environment.features.userCanSeeClientes;

    public getRequest = this.tareasApi.ecorregimenes.GET;
    public deleteRequest = this.tareasApi.ecorregimenes.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public filter: Filtering<EcorregimenModel> = new Filtering<EcorregimenModel>();
    public fincaName = environment.features.fincaName;
    public sectorName = environment.features.sectorName;
    public parcelaName = environment.features.parcelaName;

    public pathToForm = 'tareas-crear-ecorregimenes';
    public pathToEdit = 'tareas-editar-ecorregimenes';
    public pathToDuplicate = 'tareas-duplicar-ecorregimenes';

    public formRequest = new AppFormRequest<EcorregimenModel>();

    public override canShow: Record<string, boolean> = {};
    public override model: EcorregimenModel = {};

    public cols: ColInterface[] = [
        { field: 'finca', header: this.fincaName },
        { field: 'sector', header: this.sectorName },
        { field: 'parcelas', header: this.parcelaName },
        { field: 'fecha_establecimiento_cv', header: 'F. Establecimiento C. Vegetal' },
        { field: 'nombre_mantenimiento', header: 'Tipo Mantenimiento C. Vegetal' },
        { field: 'anchura_cv', header: 'Anchura C. Vegetal' },
        { field: 'anchura_libre_proyeccion_1', header: 'Anchura Libre Proyección V.' },
        { field: 'fecha_establecimiento_ci', header: 'F. Establecimiento C. Inerte' },
        { field: 'anchura_ci', header: 'Tipo Mantenimiento C. Inerte' },
        { field: 'anchura_libre_proyeccion_2', header: 'Anchura Libre Proyección I.' },
    ];

    public formFieldsPlaces: ItemInterface<object>[] = [
        { 
            field: 'id_finca', 
            label: 'Finca', 
            inputType: {type: InputType.TEXT }, 
        },
        { 
            field: 'id_sector',
            label: 'Sector',
            inputType: { type: InputType.TEXT },
        },
        { 
            field: 'id_parcelas', 
            label: this.parcelaName, 
            inputType: {type: InputType.TEXT },
        }
    ];

    public formFields: ItemInterface<object>[] = [
        { 
            field: 'fecha_establecimiento_cv', 
            name: 'Fecha Establecimiento Cubierta Vegetal', 
            label: 'Fecha Establecimiento Cubierta Vegetal', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'anchura_cv', 
            name: 'Anchura Cubierta Vegetal (m)', 
            label: 'Anchura Cubierta Vegetal (m)', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'nombre_mantenimiento', 
            name: 'Tipo Mantenimiento Cubierta Vegetal', 
            label: 'Tipo Mantenimiento Cubierta Vegetal', 
            inputType: { type: InputType.TEXT } 
        },
        {
            field: 'anchura_libre_proyeccion_1',
            name: 'Anchura Libre Proyeccion Copa (m)',
            label: 'Anchura Libre Proyeccion Copa (m)',
            inputType: { type: InputType.TEXT } 
        },

    ];

    public formFieldsCubiertasInertes: ItemInterface<object>[] = [
        { 
            field: 'fecha_establecimiento_ci', 
            name: 'Fecha Establecimiento Cubierta Inerte', 
            label: 'Fecha Establecimiento Cubierta Inerte', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'anchura_ci', 
            name: 'Anchura Cubierta Inerte (m)',
            label: 'Anchura Cubierta Inerte (m)', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: '', 
            label: '', 
            inputType: { type: InputType.EMPTY } 
        },
        { 
            field: 'anchura_libre_proyeccion_2',
            name: 'Anchura libre de la Proyección Copa (m)', 
            label: 'Anchura libre de la Proyección Copa (m)', 
            inputType: { type: InputType.TEXT } 
        },
    ];

    public formFieldsArroz: ItemInterface<object>[] = [
        { field: 'fecha_arroz', label: 'Fecha Arroz', inputType: { type: InputType.TEXT } },
        { field: 'labor', label: 'Labor', inputType: { type: InputType.TEXT } },
        { 
            field: 'tipo_operacion', 
            label: 'Tipo Operación', 
            inputType: { type: InputType.TEXT }, 
        },
        { 
            field: 'id_maquinaria', 
            label: 'Maquinaria', 
            inputType: { type: InputType.TEXT }, 
        },
        { field: 'observaciones', label: 'Observaciones', inputType: { type: InputType.TEXTAREA } },
    ];


    constructor(dashboard: DashboardService,
        private router: Router,
        private tareasApi: TareasApiService) {
        super(
            dashboard,
            tareasApi.ecorregimenes.GET,
            tareasApi.ecorregimenes.DELETE
        );
    }

    public add() {
        this.router.navigate(['dashboard', this.pathToForm]);
    }

    public edit(data: EcorregimenModel) {
        this.router.navigate(['dashboard', this.pathToEdit, data.id]);
    }

    public duplicate(data: EcorregimenModel) {
        this.router.navigate(['dashboard', this.pathToDuplicate, data.id]);
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (col.field === 'horas_totales') {
            return {
                textAlign: 'center'
            };
        }

        if (Utils.isNumber(value) && col.field !== 'finca') {
            return {
                textAlign: 'right',
            };
        }
        if (col.field === 'nombre_cuba') {
            return {
                textAlign: 'center',
            };
        }
        if (col.field === 'finca') {
            return {
                textAlign: 'left',
            };
        }
        return {};
    }

  

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields,
            this.formFieldsCubiertasInertes
        ];
  
        const csvContent = createCsv(arrayFormFields, this.filter.value, 'anchura_libre_proyeccion_2');
  
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

        csvLink.download = 'Tareas Ecorrégimenes.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
    }

    public globalVisualTransform(value: string, col: ColInterface, row: EcorregimenModel) {

        if (Utils.isNumber(value) && col.field !== 'finca') {
            // formato decimal y millar
            return Utils.decimalFormat(value, 2, '.', ',', 3);
        }

        if (col.field === 'parcelas') {
            const str = Utils.replaceAll(Utils.replaceAll((row[col.field]?.toString() || ''), '- ', ''), '\n', '\n, ');
            value = str.substring(0, str.length - 2);
            return (value.length > 50) ? value.slice(0, 50 - 1) + '…' : value;
        }

        return value;
    }
}

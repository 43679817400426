import {Filtering} from './../service/filtering/filtering';
import {RequestHandler} from '../service/OffService/request-handler';

export class BaseListView<T> {
    public request = new RequestHandler<T>();
    public filtering = new Filtering<T>();
    public fieldsToGlobalSearch: string[] = [];
    public dropdown: Record<string,Record<string,string>[]> = {};
    public multiselect: Record<string,Record<string,string>[]> = {};
    public defaultDate: Date = new Date();
    public searchOn: string[] = [];
    public searchPriority: string[] = [];

    public filters: {
        global: string, 
        dropdown: Record<string,Record<string,string>>, 
        multiselect: Record<string,string>, 
        dateRange: Record<string,string>
    } = {
            global: '',
            dropdown: {},
            multiselect: {},
            dateRange: {}
        };

    constructor(request?: RequestHandler<T>, searchOn?: string[], searchPriority?: string[]) {
        if (request) {
            this.searchOn = (searchOn || []);
            this.searchPriority = (searchPriority || []);
            this.request = request;
            this.request.safePerform();
            this.initFiltering();
            this.onResponse();
        }
    }

    public initRequest(request: RequestHandler<T>) {
        this.request = request;
        this.request.safePerform();
        this.initFiltering();
        this.onResponse();
    }

    public onResponse() {
        this.request.response((value: T[] | T) => {
            this.filtering.setValue(value as T[]);
            this.filtering.filter();
        });
    }

    public setGlobalContentFilter(content: EventTarget | string | null) {
        if (typeof content !== 'string' && content instanceof EventTarget) {
            const inputElement = content as HTMLInputElement;
            content = inputElement.value;
        }
        
        if (this.filters && this.filtering) {
            this.filters.global = content as string;
            this.filtering.filter();
        }
    }

    public setDropdownSelectOn(content: string, tag: string) {
        this.cleanMultiSelect();

        this.filters.dropdown[tag] = {
            tag: tag,
            content: content
        };

        this.filtering.filter();
    }


    public setMultiSelectOn(content: string, tag: string) {
        this.filters.multiselect[tag] = content;
        this.filtering.filter();
    }

    public setDateRangeOn() {
        this.filtering.filter();
    }

    public cleanDateRangeOn(tag: string) {
        this.filters.dateRange[tag] = '';
        this.filtering.filter();
    }

    private initFiltering() {
        this.filtering = (this.filtering || new Filtering<T>());

        //FILL DROPDOWNS
        this.filtering.addFilter((data: T[] | void) => {
            for (const x in this.filters.dropdown) {
                if (this.filters.dropdown[x]) {
                    this.dropdown[x] = [{
                        label: '...',
                        value: ''
                    }];

                    if (data) {
                        this.filtering
                            .distinctObjectCollection(data, x)
                            .forEach(it => {
                                this.dropdown[x]?.push({
                                    label: it ?? '',
                                    value: it ?? ''
                                });
                            });
                    }
                }}

            return data;
        });

        //FILTER BY DROWDOWNS
        this.filtering.addFilter((data: T[] | void) => {
            let copy = data || [];

            for (const x in this.filters.dropdown) {if (this.filters.dropdown[x]) {
                const dropdown = this.filters.dropdown[x];

                if (copy
                    && dropdown
                    && dropdown['tag']
                    && dropdown['content']) {

                    copy = copy
                        .filter((it: T) => this.filtering.like(
                            (it as Record<string,string>)[dropdown['tag'] ?? ''] ?? '',
                            dropdown['content'] ?? ''));
                }
            }}

            return copy;
        });

        //FILL MULTISELECT
        this.filtering.addFilter((data: T[] | void) => {
            for (const x in this.filters.multiselect) {if (this.filters.multiselect[x]) {
                this.multiselect[x] = [];

                if (data) {
                    this.filtering
                        .distinctObjectCollection(data, x)
                        .forEach(it => {
                            this.multiselect[x]?.push({
                                label: it ?? '',
                                value: it ?? ''
                            });
                        });
                }
            }}

            return data;
        });

        //FILTER BY MULTISELECT
        this.filtering.addFilter((data: T[] | void) => {
            let copy = data || [];

            for (const x in this.filters.multiselect) {if (this.filters.multiselect[x]) {
                const multiselect = this.filters.multiselect[x];

                if (copy && multiselect) {
                    copy = copy
                        .filter((it: T) => {
                            if (multiselect.length > 0) {
                                return multiselect.indexOf((it as Record<string,string>)[x] ?? '') >= 0;
                            } else {
                                return true;
                            }
                        });
                }
            }}

            return copy;
        });

        //FILTER BY DATE RANGE
        this.filtering.addFilter((data: T[] | void) => {
            let copy = data || [];

            for (const x in this.filters.dateRange) {if (this.filters.dateRange[x]) {
                const dateRange = this.filters.dateRange[x];

                if (copy && dateRange) {
                    copy = copy
                        .filter((it: T) => {
                            const dateA = new Date(dateRange[0] ?? ''),
                                dateB = new Date(dateRange[1] ?? ''),
                                selected = this.getDate((it as Record<string,string>)[x] ?? '', '/');

                            return selected >= dateA && selected <= dateB;
                        });
                }
            }}

            return copy;
        });

        //SET DEFAULT DATE
        this.filtering.addFilter((data: T[] | void) => 
        /*if (data) {
                this.defaultDate = this.getDate(data[0].fecha, '/');
            }*/

            data
        );

        //GLOBAL OBJECT COLLECTION FILTER
        this.filtering.addFilter((
            data: T[] | void, 
            self: Filtering<T> | void
        ) => (self as Filtering<T>).filterObjectCollection(
            this.filters.global, 
            data as T[], 
            this.searchOn, 
            this.searchPriority)
        );

        //SORTING
        this.filtering.addFilter((data: T[] | void) => (data as T[]).sort((a: T, b: T) =>
            parseInt((a as Record<string,string>)['id'] ?? '', 10) > parseInt((b as Record<string,string>)['id'] ?? '', 10) ? -1 : 1
        ));

    }

    private getDate(date: string, separator: string): Date {
        const datePart = date.split(separator);
        const day = datePart[0];
        const month = datePart[1];
        const year = datePart[2];
        return new Date([month, day, year].join('/'));
    }

    private cleanMultiSelect() {
        for (const x in this.filters.multiselect) {
            if (x) {
                this.filters.multiselect[x] = '';
            }
        }
    }
}

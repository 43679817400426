
<p-panel>
    
    <h4>Datos de {{parcelaName}}</h4>
    <app-form-builder
            [model]="model"
            [undoEnabled]="undoEnabled"
            [distributionColumns]="4"
            [distributionOrder]="hasPlantas ? [2, 4, 4, 2] : [4]"
            [fields]="formFieldsDatosParcela"
            (formChanges)="formChanges($event)">
    </app-form-builder>
    <app-form-builder *ngIf="viewNumeroMetros"
            [model]="model"
            [undoEnabled]="undoEnabled"
            [distributionColumns]="3"
            [fields]="formFieldsNumMetros"
            (formChanges)="formChanges($event)">
    </app-form-builder>
    <div *ngIf="otros">
    <app-form-builder 
            [model]="model"
            [undoEnabled]="undoEnabled"
            [distributionColumns]="4"
            [fields]="formFieldsDatosParcelaOtros"
            (formChanges)="formChanges($event)">
    </app-form-builder>
    </div>
    <br>
    <br>
    <div class="row">
       <div class="col col-md-2"><h4>Datos SigPAC</h4></div> 
       <div *ngIf="showMapas" class="col col-md-1 col-offset-1">
            <button class="btn btn-warning" style="margin-left:0px !important" (click)="abreMapa();">
            <i class="fa fa-search" aria-hidden="true"></i>
            <span> Mapa</span>
            </button>
        </div>
        <div *ngIf="showSigPacParcela" class="col col-md-1">
            <button class="btn" style="margin-left:0px !important; background-color: #16A2B7; color: white" (click)="openSigpac();">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span> SigPAC</span>
            </button>
        </div>
    </div>
    <app-form-builder
            [model]="model"
            [undoEnabled]="undoEnabled"
            [distributionColumns]="4"
            [fields]="formFieldsDatosSigpac"
            
            (formChanges)="formChanges($event)">
    </app-form-builder>
    <br>
    <br>
    <h4>Datos generales</h4>
    <app-form-builder
            [model]="model"
            [undoEnabled]="undoEnabled"
            [distributionColumns]="4"
            [fields]="formFieldsDatosGenerales"
            
            (formChanges)="formChanges($event)">
    </app-form-builder>
    <br>

    <div *ngIf="showDatosMedioambientales">
        <h4>Puntos de captación de agua</h4>
        <app-form-builder
            [model]="model"
            [undoEnabled]="undoEnabled"
            [distributionColumns]="4"
            [fields]="formFieldsDatosMedioambientales"
            
            (formChanges)="formChanges($event)">
        </app-form-builder>
        <br>

        <h4>Parcelas en zonas específicas</h4>
        <app-form-builder
            [model]="model"
            [undoEnabled]="undoEnabled"
            [distributionColumns]="4"
            [fields]="formFieldsZonasEspecificas"
            
            (formChanges)="formChanges($event)">
        </app-form-builder>
        <br>
    </div>
    <br>

    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>

<p-dialog [header]="'Mapa2 ' + parcelaName" [(visible)]="mapaAbierto" #mapaDialog (onShow)="scrollToTop()">
                         
  <div *ngIf="mapaAbierto" style="height:86%; margin-left:20px; margin-right:20px; width: 80vw !important">
    <app-mapa-add
        [origin]="origin"
        [finca]="finca"
        [localidad]="localidad"
        [cultivo]="cultivo"
        [variedad]="variedad"
        [parcelaNombre]="parcelaNombre"
        (fromMapa)="getFromMapa($event)">
    </app-mapa-add>
  </div>
  <br>

</p-dialog>

<app-dialog-sigpac-form  #sigpac  (sendSigPac)="getSigPac($event)"></app-dialog-sigpac-form>
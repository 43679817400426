<br>

<div>
    <div class="d-flex justify-content-start align-content-center">
        <div *ngIf="showTrabajadoresCuadrillas" class="mr-2 d-flex align-items-center">
            <span class="mr-1">Cuadrilla:</span>
            <p-dropdown
                [options]="cuadrillas"
                [(ngModel)]="cuadrilla"
                (onChange)="formChanges('cuadrilla')">
            </p-dropdown>
        </div>

        <div *ngIf="showFichajesCargos" class="mr-2 d-flex align-items-center">
            <span class="mr-1">Cargo almacén:</span>
            <p-dropdown
                [options]="cargos"
                [(ngModel)]="cargo"
                (onChange)="formChanges('cargo')">
            </p-dropdown>
        </div>

        <div *ngIf="appName === 'naturfrut'" class="d-flex justify-content-start align-items-center">
            <span class="m-1">
                Almacén
            </span>
            <div style="font-size: 0.7rem" class="btn-group">
                <button (click)="formChanges('es-almacen')"
                    [ngClass]="almacenFilter === 'es-almacen' ? 'btn btn-primary' : 'btn btn-light'"
                    style="
                        height: 28px;
                        width: 50px;
                        border: 1px solid grey;
                        border-right: 1px solid grey">
                    SÍ
                </button>
                <button (click)="formChanges('no-es-almacen')"
                    [ngClass]="almacenFilter === 'no-es-almacen' ? 'btn btn-primary' : 'btn btn-light'"
                    style="
                        height: 28px;
                        width: 50px;
                        border: 1px solid grey;
                        border-right: 1px solid grey">
                    NO
                </button>
            </div>
        </div>
    </div>

    <app-common-list-table
        [request]="getRequest"
        [cols]="cols"
        [hideSearch]="true"
        (fieldEdit)="editField($event)"
        [globalConditionalStyle]="globalConditionalStyle"
        [globalVisualTransform]="globalVisualTransform"
        [filtering]="filtering">

        <ng-template #caption>
            <div class="d-flex flex-wrap align-items-center" style="margin-left: -0.70rem;">
                <div class="d-flex align-items-center">
                    <span class="m-1">Fecha:</span>
                    <p-calendar [inputStyle]="{maxWidth: '90px', padding: '0.06rem'}" [style]="{fontSize: '.7rem !important'}"
                        [(ngModel)]="date"
                        [locale]="es"
                        (ngModelChange)="changeDate()"
                        dateFormat="dd/mm/yy"
                        appendTo="body"
                        placeholder="Fecha...">
                    </p-calendar>
                </div>
                <div class="d-flex align-items-center ml-3">
                    <span class="m-1">Mañana:</span>
                    <input #horaEntrada
                        pInputText
                        class="m-1"
                        type="time"
                        step="60"
                        (focusout)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)"
                        (blur)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)" 
                        (keyup)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)"/>

                    <input #horaSalida
                        pInputText
                        class="m-1"
                        type="time"
                        step="60"
                        (focusout)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)"
                        (blur)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)" 
                        (keyup)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)"/>
                </div>
                <div class="d-flex align-items-center ml-3">
                    <span class="m-1">Tarde:</span>
                    <input #horaEntradaTarde
                        pInputText
                        class="m-1"
                        type="time"
                        step="60"
                        (focusout)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)"
                        (blur)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)" 
                        (keyup)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)"/>
                    <input #horaSalidaTarde
                        pInputText
                        class="m-1"
                        type="time"
                        step="60"
                        (focusout)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)"
                        (blur)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)" 
                        (keyup)="autofillHoras(horas, horaEntrada, horaSalida,
                                    horaEntradaTarde, horaSalidaTarde)"/>
                </div>
                <div class="d-flex align-items-center ml-3">
                    <span class="m-1">Horas:</span>
                    <input #horas
                        pInputText
                        style="max-width: 50px;"
                        class="m-1"
                        type="number"
                        placeholder="Horas" />
                </div>
                <div class="d-flex">
                    <button class="btn btn-sm btn-primary m-1 ml-2"
                            [disabled]="isPerforming"
                            (click)="save(+horas.value, horaEntrada.value, horaSalida.value,
                                        horaEntradaTarde.value, horaSalidaTarde.value)">
                            {{isPerforming ? 'Fichando...' : 'Fichar'}}
                    </button>
                    <button class="btn btn-sm btn-primary m-1"
                            [disabled]="isPerforming"
                            (click)="edit()">
                            <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-sm btn-primary m-1"
                            [disabled]="isPerforming"
                            (click)="save()">
                            <i class="fa fa-save"></i>
                    </button>
                </div>
                <div *ngIf="showFichajesExportarCSV" class="m-1">
                    <button class="btn btn-success" (click)="exportarCSV()" [disabled]="isDownloadingCsv">
                        {{isDownloadingCsv ? 'Generando csv...' : 'Exportar a CSV'}}
                    </button>
                </div>
            </div>
        </ng-template>
    </app-common-list-table>
</div>

<a href="#" id="csvLink" style="visibility: hidden; position: fixed;"></a>
